.detailstation{

.background {
    min-height: 50vh;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url('../../assets/header_bck_lst-station.webp');
    display: flex;
    justify-content: center;
    align-items: center;
    
  } 

.textbox{
  max-width: $with-max-content;
}

.textbox h1{
  margin-top: 16px;
  max-width: 800px;
  text-align: left;
}

.textbox p{
 margin-top: 32px;
  max-width: 800px;
 text-align: left;
}


.bloc-rubrique{
  background: $fond-accentue;
  //background: $typo-foncee;
  margin-bottom: 40px;
  padding: 8px;
}


.top_class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  
  
}

.top_class h2 {
    padding: 15px;
    color: $typo-foncee;
    text-align: center;
    }
.top_class h4 {
  //color: $couleur-selection
  color: $typo_claire
}

.leaflet-container {
	max-width: 1140px;
	height: 400px; 
	margin: 8px;
	z-index: 40;
}
	
.section-detail_station{
    display : flex;
    align-items: center;
    justify-content: center; 
}

.bloc-detail-station{
    width: 1140px;
    margin-top: 40px; 
    margin-left: 16px;
    margin-right: 16px;
	  margin-bottom: 40px; 
}

.det_stat_info{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  
} 

.det_stat_ss_element{
  width: 255px;
  height: 230px;
  border-radius: 3%;
  background: $fond-clair; 
  margin: 24px;
  color: $typo-foncee;
  justify-content: center;
  align-items: center;
  }
   
.det_stat_ss_top h3{
  display: flex; 
  height: 70px;
  justify-content: center;
  align-items: center;
  padding: 1px 1px;
  background: $typo-claire;
  color: $fond-clair; 
  font-weight: normal;
  }

  .det_info_station {
    
    padding-top: 15px; 
    padding-bottom: 20px; 
   }

   
   .det_info_label {
    background: $fond-accentue;
    padding-top: 15px; 
    padding-bottom: 20px; 
   }





  .det_stat_ss_block ul {
   height: 160px;
   margin: 4px;
   padding: 2px; 
   display: flex;
   flex-flow: row wrap;
   justify-content: space-around;
   align-items: center;
   flex-direction: column;

   
 }

.det_stat_ss_block ul li{
 //margin: 4px; 
 list-style: none;
  text-align: center;
}

.internalLink {
 //padding: 8px;	
  margin-top: 8px;
  margin-bottom: 8px;
 
}

.internalLink a{
 //padding: 8px;
 display: flex;
 flex-flow: row wrap;
 justify-content: space-around;
 align-items: center;
 color: $typo-claire;
 text-decoration: none;
 &::after{
 content:'';
 width: 0%;
 height: 2px;
 background: $couleur-selection;
 margin: auto;
 transition: 0.5s;
}
&:hover::after{
 width: 100%;
}
}

.externalLink{
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
  .list_flag_track img{
    height: 16px;
    width: 16px;
    margin-right: 5px;

  }
.icon_flag_green {
  filter: invert(22%) sepia(89%) saturate(5331%) hue-rotate(108deg) brightness(97%) contrast(102%);  
}

.icon_flag_blue {
  filter: invert(7%) sepia(100%) saturate(7422%) hue-rotate(247deg) brightness(114%) contrast(142%);
}
.icon_flag_red {
  filter: invert(30%) sepia(100%) saturate(6720%) hue-rotate(355deg) brightness(94%) contrast(124%);
}



.icon_lien {
  height: 80px;
  width: 80px;
  filter: invert(36%) sepia(77%) saturate(380%) hue-rotate(172deg) brightness(94%) contrast(84%);
}

.det_info_txt{
  margin: 24px;
  padding: 24px;
  background-color: $fond-clair;
}

  .det_info_label img {
  // display:block;
  // justify-content: center;
  // align-items: center;
   height: 100px;
  width: 100px;
}

  @media screen and (max-width: 900px) {

	.background {
		min-height: 30vh;
	} 

	
   
	.textbox h1 {
		font-size: 50px;
	}

	.textbox p {
		font-size: 14px;
	}

}


@media screen and (max-width: 600px) {

	.background {
		min-height: 45vh;
	} 

  .textbox{
    width: 100%;
       
  }

	.textbox h1 {
    margin: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 24px;
	}

	.textbox p {
    margin: 16px;
		font-size: 16px;
	}

}

@media screen and (max-width: 400px) {
  .background {
    min-height: 60vh;
   
  } 
}

};