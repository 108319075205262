@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap);


$color-1: #fff;
$color-2: #f44336;
$color-3: #436C9D;
$color-4: #0A203A;
$color-5: #f9f9f9;
$color-6: #5B86B9;

/*
$typo-claire: #436C9D;
$typo-foncee: #0A203A;
$fond-clair: #E8EDF3;
$fond-accentue: #C2DAF0;
$couleur-selection: #f44336;
*/


$typo-claire: #436C9D;
$typo_claire: #436C9D;
$typo-foncee: #253B55;
$fond-clair: #FBFBFF;
$fond-accentue: #F1F5F9;
$couleur-selection: #f44336;


$with-max-content : 1140px;


* { 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}


