.grille_detail{
    color: $typo-foncee;
    width: 330px;
    max-height: 542px;
    border-radius: 3%;
    background: $fond-clair;
    margin: 20px 20px 20px 20px;
  }
  
 .top_grille{
    max-height: 230px;
 }

  .photo_station{
        border-radius: 3% 3% 0 0;
        max-height: 230px;
        width: 100%;
        opacity : 0,7;
    }

  .grille_region{
     //grid-column: 1/-1;
     // grid-row: 1/2;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .grille_region a{
      text-decoration: none;
      color: $typo-claire;
      padding: 8px;
      
  }
  
  .grille_region a::after{
      content:'';
      width: 0%;
      height: 2px;
      background: $couleur-selection;
      display: block;
      margin: auto;
      transition: 0.4s;
  }
  .grille_region a:hover::after{
      width: 100%;
  }
  
 
  .grille_domaine{
      //   grid-column: 1/-1;
      //  grid-row: 2/3;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      }
  
  .grille_domaine a{
      text-decoration: none;
      //color: inherit;
     // color: $color-3;
    &::after{
        content:'';
        width: 0%;
        height: 2px;
        background: $couleur-selection;
        display: block;
        margin: auto;
        transition: 0.5s;
    }
    &:hover::after{
        width: 100%;
    }
  }
  
  
  .grille_domaine_km{
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .grille_type_liaison{
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .grille_station{
      position: relative;
      top: -120px;  
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      background: $typo-claire;
      opacity : 0.97;
      color: inherit;
      color: $fond-clair;
      padding: 15px 20px 20px 15px;
       &:hover{
        background: $couleur-selection;
        transition: 1s;
        color: $fond-clair
    } 
  }
  
  .grille_station a {
   
    text-decoration: none;
      color: $fond-clair;
     
  }

  
  .grille_logo{
      display: flex;
      justify-content: center;
      align-items: center;
      }
  .grille_hauteur{
      position: relative; 
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3px;
  }

  .icon_moutain{
    max-width: 20px;
    max-height: 20px;
    margin: 3px;
}
  
  .grille_nb_meca{
      position: relative; 
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .icon_ski{
    max-width: 20px;
    max-height: 20px;
    margin: 3px;
}

  .grille_nb_pist_verte{
      grid-column: 3/4;
      grid-row: 5/6;
      font-size: 10px;
  }
  .grille_nb_pist_bleue{
      grid-column: 4/5;
      grid-row: 5/6;
      font-size: 10px;
  }
  .grille_nb_pist_rouge{
      grid-column: 3/4;
      grid-row: 6/7;
      font-size: 10px;
  }
  .grille_nb_pist_noire{
      grid-column: 4/5;
      grid-row: 6/7;
      font-size: 10px;
  }
  .grille_station_km{
 //      grid-column: 1/-1;
//      grid-row: 9/10;
position: relative; 
   //   top: -60px;     
font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .icon_ski_lift{
    max-width: 20px;
    max-height: 20px;
    margin: 3px;
}

  .grille_label{
    grid-column: 1/-1;
    grid-row: 10/11;
    display: flex;
    justify-content: center;
    align-items: center;
      height: 50px;
  
    }
  .grille_label img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
  }

  hr{
   margin-top: 10px;
   margin-bottom: 10px;
    opacity : 0.3;
  }