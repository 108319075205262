.credits{
  .background {
    min-height: 30vh;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url('../../assets/header_bck.webp');
    }  
  
    .body-block{
      display : flex;
      align-items: flex-start;
      justify-content: center; 
    }
  
    .section-credit-photo{
      width: 1140px;
      margin-top: 56px; 
      margin-bottom: 56px;
      margin-left: 16px;
      margin-right: 16px;
    }

    .bloc-credit-photo-header{
     padding-bottom: 8px;
      padding : 4px;
    }

   
    .sous-bloc{
      padding : 8px;
      display : flex;
      align-items: flex-start;
      justify-content: flex-start; 
    }

    .sous-bloc ul{
      list-style: none;
    }

    .sous-bloc li{
      padding: 4px;
    }
}