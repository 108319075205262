.home {

    .background {
        min-height: 100vh;
        background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url('../../assets/header_bck.webp');
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-textbox {
        color: $fond-clair;
        position: absolute;
        max-width: 1140px;
        padding: 16px;
        margin: 16px;
    }

    .header-texbox-block-text {
        display : flex;
        align-items: flex-start;
        justify-content: center;    
    }

    .header-texbox-title{
        padding-left: 8px;
        margin-bottom: 40px;
    }

    .header-texbox-block-text-left{
        width: 50%;
        padding: 8px;
        margin: 16px;
        font-size: 1.5em;
    }

    .header-texbox-block-text-right{
        width: 50%;     
    }

    .header-texbox-block-text-right p{
        padding: 8px;
        margin: 16px;
        border-left: solid;
        border-color: $couleur-selection;
        ;
    }

    .header-texbox-block-url{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    .section-fonction {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 80px;       
    }    

    .fonction-block-center {
        color : $typo-foncee;
        max-width: 1140px;
        display : flex;
        align-items: flex-start;
        justify-content: center;
    }

    .fonction-block-image {
        width: 38%;
    }

    .fonction-block-image img{
        width: 100%;
        object-fit: contain;      
    }

    .fonction-block-text{
        width: 62%;
        margin: 8px;
    }

    .fonction-block-text h2{
        padding-top: 8px;
        padding-bottom: 16px;
    }
    .fonction-block-list-element{
        list-style: none;
        display : flex;
        align-items: flex-start;
        justify-content: center;
    }
    
    .fonction-list-img{
        margin: 8px;
        padding-top: 8px;
    }

    .fonction-list-data{
        margin: 16px;
        ;
    }

    .fonction-list-img img{
        width: 36px;

    }

    .fonction-list-data-title{
        padding-bottom: 8px;
        font-size: 20px;
        color: $color-3;
    }

    .section-key-number{
        background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url('../../assets/bck_homepage_bandeau.webp');
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover; 
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 80px;   
    }

    .section-key-number-grp{
        width: 1140px;
        color: $fond-clair;
    }

    .section-key-number-grp-title{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section-key-number-grp-title{
        margin-bottom: 32px;
    }

    .grp-key-number-block{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .key-number-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px;
    }

    .section-discover-massif{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 80px;   
    }

    .discover-massif-block{
        width: 1140px;
    }

    .discover-massif-group{
        color : $typo-foncee;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .discover-massif-title{
        color : $typo-foncee;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 32px;
    }

    .discover-massif-element{
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
    }

    .discover-massif-element-img{
       // witdh: 100%;
        padding: 16px;
    }

    .discover-massif-element-img img{
        object-fit: cover;
        width: 200px;
        height: 400px;
    }

    .discover-massif-element-text{
        display: flex;
        align-items: center;
        justify-content: center; 
        flex-direction: column; 
    }

    .discover-massif-element-title{
        padding: 8px;
        font-size: 20px;
        color: $color-3;
    }

    .discover-massif-element-trait{
        width: 50px;
        border-top: solid;
        border-color: $couleur-selection;
    }

    .discover-massif-element-nbstation{
        padding:8px;
    }

    .discover-massif-element-link{
        margin-top: 15px;
        margin-bottom: 50px;
    }
   
    .discover-massif-element-link a{
        padding:8px; 
        border : dotted; 
        color: $color-3;
        border-color: $color-3;
        border-width: 1px;
        text-decoration: none;
        
        cursor: pointer;
        &:hover{
            border: 1px solid $couleur-selection;
            background: $color-2;
            transition: 1s;
            color: $fond-clair;
        }
        
    }

    @media screen and (max-width: 600px) {

        .header-textbox {
            
            max-width: 580px;
            padding: 8px;
            margin: 8px;
        }
    
        .header-texbox-block-text {
            flex-direction: column;   
        }
    
        .header-texbox-title{
            padding-left: 8px;
            margin-bottom: 24px;
        }
    
        .header-texbox-block-text-left{
            width: 100%;
            padding: 8px;
            margin: 8px;
            }
    
        .header-texbox-block-text-right{
            width: 100%;     
        }
    
            
        .header-texbox-block-url{
            margin-top: 32px;
        }
    
        .section-fonction {
            margin-top: 54px;       
        }    
    
        // .fonction-block-center {
        //     max-width: 500px;
        // }
    
        .fonction-block-image {
            display: none;
        }
    
    
        .fonction-block-text{
            width: 100%;
            margin: 16px;
        }
        
        .section-key-number{
            height: 300px;
            margin-top: 56px;
        }
    
        .section-key-number-grp{
            width: 100%;
        }
    
    
        .section-key-number-grp-title{
            margin: 16px;
        }
            
        
    
        .key-number-icon{
            margin: 8px;
        }
    
        .section-discover-massif{
          //  width: 500px;
            margin-top: 56px;   
        }
    
        .discover-massif-block{
            width: 100%;
        }
    
        .discover-massif-group{
            //flex-wrap: wrap;
            flex-direction: column;
            //width: 100%;
        }
    
        .discover-massif-title{
            margin: 16px;
            justify-content: flex-start;
        }
        
        .discover-massif-element-img{
            padding: 8px;
        }
    
        .discover-massif-element-img img{
            width: 150px;
            height: 300px;
        }
        .discover-massif-element-trait{
            width: 30px;
        }   

    }
    @media screen and (max-width: 400px) {
        .header-texbox-block-text-left{
            
            padding: 4px;
            font-size: 1em;
        }
    
    
        .header-texbox-block-text-right p{
            padding: 4px;
            font-size: 0.75em;
            
            ;
        }
    }


}