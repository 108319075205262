.about {

    .background {
       min-height: 30vh;
       background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url('../../assets/header_bck.webp');
       }
}

.section-about{
    display : flex;
    align-items: flex-start;
    justify-content: center; 
}

.bloc-about{
    width: 1140px;
    margin-top: 56px; 
    margin-bottom: 56px;
    margin-left: 16px;
    margin-right: 16px;
}

.sous-bloc{
    display : flex;
    align-items: flex-start;
    justify-content: center; 
}

.sous-bloc-text{
    width: 65%;
    margin-left: 16px;
    margin-right: 16px;
}

.sous-bloc-text h2{
    padding: 8px;
}

.sous-bloc-text p{
    padding: 8px;
}

.sous-block-image{
    width: 35%;
}

.sous-block-image img{
    width: 100%;
    object-fit: contain; 
}

.bloc-line{
    display : flex;
    align-items: center;
    justify-content: center;  
}


.line{
    width: 80px;
    margin: 40px;
    border-top: solid;
    border-color: $color-2;
}

@media screen and (max-width: 600px) {
    .sous-bloc{
        flex-direction: column;  
    }
    .sous-bloc-text{
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 16px;
    
    }
    
    .sous-block-image{
        width: 100%;
    }
     
}