nav{
    position: fixed; 
    padding: 8px 16px;
    height: 100px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    
  //  overflow: hidden;
}

.nav-bg{
    //backdrop-filter: blur(25px);
    background: $color-4;
};

.sous-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
};

.nav-links{
    flex: 1;
    text-align: right;
};


.nav img{
    width: 75px ;
    height: 75px;
};

.menu-btn {
    display: none;
    margin-right: 10px; 
};

.menu-btn img {
    padding: 12px;  /* Pour créer une zone tactile suffisante */
    width: 24px;    /* Taille de l'icône elle-même */
    height: 24px;
    min-height: 44px; /* Zone tactile minimale */
    min-width: 44px;
};

.nav-links{
    flex: 1;
    text-align: right;
}

.nav-links ul li{
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
    &::after{
        content:'';
        width: 0%;
        height: 2px;
        background: #f44336;
        display: block;
        margin: auto;
        transition: 0.5s;
    }
    &:hover::after{
        width: 100%;
       }
}

.nav-links ul li a{
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .menu-btn {
        display: flex;
        flex-direction: column;
    };
    .nav-links {
        transform: translateY(-100%); 
        position: absolute; 
        top : 0;
        left : 0;  
       // transition: all 1200ms cubic-bezier(0.3, 0.96, 0.59, 0.95);
   }

       .nav-links.active {
        transform: translateY(0);
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $color-4;
        justify-content:flex-start;
        align-items: center;
        z-index: 60;
        padding-bottom: 10px;
} 

    .nav-links ul{
        transform: translateY(0);
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $color-4;
        justify-content:flex-start;
        align-items: center;
    }

    .nav-links button {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .nav-links button img {
    width: 25px ;
    height: 25px;    
     }

 

    }



