.footer{
    display : flex;
    align-items: center;
    justify-content: center; 
    background-color: $color-4;
    width: 100%;
    height: 100px;     
    
    .grp-link{
    max-width: 1140px;
    }

    .grp-link a{
        color: $color-1;
        list-style: none;
        text-decoration: none;
        &::after{
            content:'';
            width: 0%;
            height: 2px;
            background: $color-2;
            display: block;
            margin: auto;
            transition: 0.5s;
        }
        &:hover::after{
            width: 100%;
           }
    }

};