.header {

    .background {
        width: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
    }

    .textbox {
        width:100%;
        color: $color-1;
        position: absolute;
        top: 80px;
     //   left : 50%;
       // transform: translate(-50%,-50%);
        text-align: center;
    }

    .textbox h1{
        margin-top: 10px;
        font-size: 40px;
    }

    .textbox p{
        margin: 10px 0 20px;
        font-size: 16px;
        color: $color-1
    }

    .hero-btn{
        display: inline-block;
        text-decoration: none;
        color: $color-1;
        border: 1px solid $color-1;
        padding: 12px 34px;
        font-size: 13px;
        background: transparent;
        position: relative;
        cursor: pointer;
        &:hover{
            border: 1px solid $color-2;
            background: $color-2;
            transition: 1s;
            color: $color-1;
        }
    }

}

